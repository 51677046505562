/* eslint-disable */
import {Vue} from 'vue-class-component';
import { Action, Getter, Mutation, State } from 'vuex-class'
import { ValidCredentials, ValidPassword } from '@/services/validation.service'
import './change-password.page.pug';
import { CustomerState } from '@/store/customer/types';
const namespace: string = 'customer';


export default class ChangePasswordPage extends Vue {
	password: string = '';
	repeatPassword: string = '';
	rememberPassword: boolean = false;
	switchWatchPass: boolean = false;
	switchWatchPassRepeat: boolean = false;
	typeInput: string = 'password';
	typeInputRepeat: string = 'password';
	isValid: string = '';

	@State('customer') customer: CustomerState | undefined;
	@Action('changePassword', {namespace}) changePassword: any;
	@Mutation('setRememberPassword', { namespace }) setRememberPassword: any;
	@Getter('getRequestStatus', { namespace }) getRequestStatus: any;

	toSwitchWatchPassword(type: string) {
		switch (type){
		case 'pass':
			this.switchWatchPass = !this.switchWatchPass;
			if(!this.switchWatchPass) {
				return this.typeInput = 'password';
			} else {
				return this.typeInput = 'text';
			}

		case 'repeat':
			this.switchWatchPassRepeat = !this.switchWatchPassRepeat;
			if(!this.switchWatchPassRepeat) {
				return this.typeInputRepeat = 'password';
			} else {
				return this.typeInputRepeat = 'text';
			}
		default:
			break;
		}

	}

	onSubmit() {
		if(ValidPassword(this.password) && this.password === this.repeatPassword) {
			this.getRequestStatus ? this.isValid = 'is-valid': '';
			this.changePassword(this.password);
			this.setRememberPassword(this.rememberPassword);
			// this.password = '';
			// this.repeatPassword = '';
		}else {
			!this.getRequestStatus ? this.isValid = 'is-invalid' : '';
			console.log(ValidPassword(this.password), this.password === this.repeatPassword);
		}
		// 	if(!this.getRequestStatus){
		// 	this.isValid = 'is-invalid';
		// 	console.log('incorrect password');
		// }else if(this.password !== this.repeatPassword){
		// 	this.isValid = 'is-invalid';
		// 	console.log('incorrect repeat password');
		// }


	}
}
